import React from 'react'
import { Link, graphql } from 'gatsby'

import Bio from '../components/bio'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Badge from '../components/badge'
import Goals from '../components/goals'
import Subscribe from '../components/subscribe'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const headerTitle = data.site.siteMetadata.title
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout title={headerTitle}>
        <SEO
          title="Opa! Eai? Aqui é o Nex!"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <Bio />
        <hr
          style={{
            marginBottom: rhythm(1),
            marginTop: -20
          }}
        />
        {/*<Goals />*/}
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const badges = node.frontmatter.badges
          return (
            <div key={node.fields.slug}>
              <h3
                style={{
                  marginBottom: rhythm(1 / 4),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                {badges? badges.map((badge) => (
                  <Badge
                    style={{ marginRight: 5}}
                    key={badge}
                    color="#28a745"
                  >
                    {badge}
                  </Badge>
                )) : null}
              <Badge color="#17a2b8">{node.frontmatter.date}</Badge>
              </div>
              <p dangerouslySetInnerHTML={{ __html: node.excerpt }} />
            </div>
          )
        })}
        <Subscribe />
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY", locale: "pt-BR")
            title
            badges
          }
        }
      }
    }
  }
`
